import React from "react";
import get from "lodash/get";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import "./blog.scss";

console.log(process.env.NODE_ENV);

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const posts = get(this, "props.data.allMarkdownRemark.edges");

    const published = posts.filter(
      (node) => node.node.frontmatter.published == true || (process.env.NODE_ENV == 'development')
    );
    return (
      <Layout>
        <div className="blog-list content">
          <Helmet title="Blog - Andrew Mahon" />
          {published.map(({ node }) => {
            let title =
                  get(node, "frontmatter.title") || node.frontmatter.path;
            if(!node.frontmatter.published) {
              title = `[DRAFT] ${title}`;
            }
            const excerpt = get(node, "frontmatter.excerpt") || node.excerpt;
            const featuredImage =
              get(node, "frontmatter.featuredImage.childImageSharp") ||
              null;
            let featuredImageElement = null;
            if (featuredImage) {
              featuredImageElement = ( <Link to={node.frontmatter.path}>
                                         <img style={{maxWidth: '100%'}} src={featuredImage.fluid.srcWebp} />
                                       </Link>);
            }

            return (
              <div className="blog-list-post" key={node.frontmatter.path}>
                <h2>
                  <Link to={node.frontmatter.path}>{title}</Link>
                </h2>
                <div className="details">
                  <p>{node.frontmatter.date}</p>
                </div>
                {featuredImageElement}
                <p>{excerpt}</p>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
query IndexQuery {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fileAbsolutePath: {regex: "/posts/.*\\.md$/"}}) {
    edges {
      node {
        frontmatter {
          date(formatString: "DD MMMM, YYYY")
          title
          path
          published
          excerpt
          featuredImage {
            id
            childImageSharp {
              fixed {
                base64
                tracedSVG
                aspectRatio
                srcWebp
                srcSetWebp
                originalName
              }
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      }
    }
  }
}
`;
